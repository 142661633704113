<template>
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="7"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="">
            <h3>Total de ventas</h3> <small> (Hay {{ totalSales }} ventas)</small>
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="5"
        >
          <div class="d-flex align-items-center justify-content-end  mb-1 mb-md-0">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Buscar por código, contacto, o empresa"
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refSaleListTable"
      :items="fetchSales"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No se encontraron registros coincidentes"
      class="position-relative"
    >
      <!-- Column: Id -->

      <!-- Column: Business name -->
      <template #cell(business_name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(data.item.client)"
              variant="light-primary"
            />
          </template>
          <span class="font-weight-bold d-block text-wrap">
            {{ data.item.client }}
          </span>
          <small class="text-muted">{{ data.item.business_name }}</small>
        </b-media>
      </template>

      <!-- Column: Sale code -->
      <template #cell(sale_code)="data">
        <b-media vertical-align="center">
          <b-link
            :to="{ name: 'sales-show', params: { id: data.item.id }}"
            class="alert-link font-weight-bold d-block text-nowrap"
          >
            {{ data.item.sale_code }}
          </b-link>
        </b-media>
      </template>

      <!-- Column: Subtotal -->
      <template #cell(subtotal)="data">
        <span class="text-nowrap">
          {{ data.item.currency_symbol }} {{ data.item.subtotal }}
        </span>
      </template>

      <!-- Column: Subtotal -->
      <template #cell(total)="data">
        <span class="text-nowrap">
          {{ data.item.currency_symbol }} {{ data.item.total }}
        </span>
      </template>

      <!-- Column: Quotation status -->
      <template #cell(quotation_status)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.quotation_status)}`"
          class="text-capitalize"
        >
          {{ data.item.quotation_status }}
        </b-badge>
      </template>

      <!-- Column: Commission -->
      <template #cell(commission)="data">
        <span
          :id="`commission-text-${data.item.id}`"
          class="text-nowrap"
        >
          {{ data.item.commission ? `${data.item.commission}% = ${data.item.currency_symbol}${(data.item.subtotal*data.item.commission/100).toFixed(2)}` : 'Sin comision' }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`sale-row-${data.item.id}-edit-icon`"
            icon="EyeIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="$router.push({ name: 'sales-show', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Ver detalle"
            class="cursor-pointer"
            :target="`sale-row-${data.item.id}-edit-icon`"
          />

          <feather-icon
            :id="`sale-row-${data.item.id}-print-icon`"
            icon="FileTextIcon"
            size="16"
            class="cursor-pointer mr-1"
            @click="openPDF(data.item)"
          />
          <b-tooltip
            title="Descargar PDF"
            :target="`sale-row-${data.item.id}-print-icon`"
          />

          <template v-if="!data.item.guide_id && data.item.quotation_status !== 'ANULADO'">
            <feather-icon
              :id="`sale-row-${data.item.id}-guide-icon`"
              icon="FileIcon"
              size="16"
              class="mr-1 cursor-pointer"
              @click="$router.push({ name: 'guides-create', params: { id: data.item.id }})"
            />
            <b-tooltip
              title="Crear guia"
              :target="`sale-row-${data.item.id}-guide-icon`"
            />
          </template>

          <template v-if="data.item.quotation_status !== 'ANULADO'">
            <feather-icon
              :id="`sale-row-${data.item.id}-cancel-icon`"
              icon="XCircleIcon"
              size="16"
              class="cursor-pointer mr-1"
            />
            <b-tooltip
              title="Anular venta"
              :target="`sale-row-${data.item.id}-cancel-icon`"
            />
            <b-popover
              :target="`sale-row-${data.item.id}-cancel-icon`"
              variant="danger"
              triggers="focus"
              placement="left"
            >
              <template #title>
                <span>Anular venta</span>
              </template>

              <div class="">
                <p>
                  ¿Está seguro anular la venta <b>{{ data.item.sale_code }}</b>?
                </p>
              </div>

              <b-button
                variant="outline-danger"
                class="mr-1"
                size="sm"
                @click="cancelSale(data.item)"
              >
                <span class="text-nowrap">Anular</span>
              </b-button>
            </b-popover>
          </template>

          <template v-if="data.item.quotation_status === 'ANULADO'">
            <feather-icon
              :id="`sale-row-${data.item.id}-delete-icon`"
              icon="TrashIcon"
              size="16"
              class="cursor-pointer"
            />
            <b-tooltip
              title="Eliminar venta"
              :target="`sale-row-${data.item.id}-delete-icon`"
            />
            <b-popover
              :target="`sale-row-${data.item.id}-delete-icon`"
              variant="danger"
              triggers="focus"
              placement="left"
            >
              <template #title>
                <span>Eliminar venta</span>
              </template>

              <div class="">
                <p>
                  ¿Estás seguro que desea eliminar la venta <b>{{ data.item.salve_code }}</b>?
                </p>
              </div>

              <b-button
                variant="outline-danger"
                class="mr-1"
                size="sm"
                @click="deleteSale(data.item)"
              >
                <span class="text-nowrap">Eliminar</span>
              </b-button>
            </b-popover>
          </template>

        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          sm="6"-->
        <!--          class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
        <!--        >-->
        <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
        <!--        </b-col>-->
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalSales"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BAvatar, BBadge,
  BCard,
  BCol,
  BFormInput, BLink,
  BMedia, BPagination,
  BRow,
  BTable, BTooltip, BPopover, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import saleStoreModule from '@/views/dramox/sale/saleStoreModule'
import saleHandler from '@/views/dramox/sale/saleHandler'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'HomeBusiness',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BTooltip,
    BLink,
    BBadge,
    BButton,
    BPopover,
  },
  data() {
    return {
    }
  },
  setup() {
    const BUSINESS_APP_STORE_MODULE_NAME = 'app-sales'

    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, saleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
      text,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    }

    const {
      tableColumns,
      currentPage,
      totalSales,
      perPage,
      searchQuery,
      refSaleListTable,
      refetchData,
      fetchSales,
      resolveStatusVariant,
    } = saleHandler()

    const openPDF = val => {
      const url = `${axios.defaults.baseURL}admin/pdf/quotation/${val.id}`
      window.open(url, '_blanck')
    }

    const cancelSale = item => {
      store.dispatch('app-sales/cancelSale', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Venta cancelada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({
            title: messageError, icon: 'AlertTriangleIcon', variant: 'danger',
          })
        })
    }

    const deleteSale = item => {
      store.dispatch('app-sales/deleteSale', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Venta eliminada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({
            title: messageError, icon: 'AlertTriangleIcon', variant: 'danger',
          })
        })
    }

    return {
      openPDF,
      cancelSale,
      deleteSale,

      fetchSales,
      tableColumns,
      currentPage,
      totalSales,
      searchQuery,
      perPage,
      refSaleListTable,
      refetchData,
      avatarText,
      resolveStatusVariant,
      // businessData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
